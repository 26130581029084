const dotenv = require("dotenv")
dotenv.config()

function getConfig(name, defaultVal) {
  if (process.env[name]) {
    return process.env[name]
  }
  try {
    if (!window) {
      console.log("Missing ENV var for ", name, " default=", defaultVal)
    }
  } catch (e) {}
  return defaultVal
}

const API_ENV = getConfig("API_ENV", "")

const config = {
  HTTPS_PORT: getConfig("HTTPS_PORT", 443),
  HTTP_PORT: getConfig("HTTP_PORT", 80),
  COUCHBASE_URL: getConfig("COUCHBASE_URL", "127.0.0.1:8091"),
  COUCHBASE_USER: getConfig("COUCHBASE_USER", null),
  COUCHBASE_PASS: getConfig("COUCHBASE_PASS", null),
  COUCHBASE_NODE_BUCKET: getConfig("COUCHBASE_NODE_BUCKET", "Node"),
  COUCHBASE_UMBRACO_BUCKET: getConfig("COUCHBASE_UMBRACO_BUCKET", "Umbraco"),

  //Couchbase expiry:
  COUCHBASE_DEFAULT_EXPIRY: parseInt(
    getConfig("COUCHBASE_DEFAULT_EXPIRY", 10800)
  ), // 3 hours (seconds)
  COUCHBASE_SESSION_EXPIRY: 7200, // 2 hours (seconds)
  COUCHBASE_IPSTSACK_DATA_EXPIRY: 1209600, // 14 days (seconds)
  COOKIE_EXPIRY: 12 * 60 * 60 * 1000, // twelve hours (ms)
  SESSION_SECRET: getConfig("SESSION_SECRET", null),
  COUCHBASE_TIMEOUT: 3000, // 3 seconds (ms)

  GENERAL_EXPIRY: parseFloat(getConfig("GENERAL_EXPIRY", 1)),

  AD_CONFIG: {
    url: getConfig("AD_CONFIG_URL", null),
    baseDN: getConfig("AD_CONFIG_BASEDN", null),
    username: getConfig("AD_CONFIG_USERNAME", null),
    password: getConfig("AD_CONFIG_PASSWORD", null)
  },

  AAD_CONFIG: {
    tenant: getConfig("AAD_CONFIG_TENANT", null),
    clientId: getConfig("AAD_CONFIG_CLIENTID", null),
    clientSecret: getConfig("AAD_CONFIG_CLIENTSECRET", null)
  },

  //Booking Api
  BOOKING_API: getConfig(
    "BOOKING_API",
    `https://booking-${API_ENV}.eshet.com/api`
  ),

  //Booking Api Shikum -hard coded stage
  BOOKING_API_SHIKUM: getConfig(
    "BOOKING_API_SHIKUM",
    `https://shikumbooking-stage.azurewebsites.net/api`
  ),

  //Subsidy Api
  SUBSIDY_API: getConfig(
    "SUBSIDY_API",
    `https://subsidy-${API_ENV}.eshet.com/api`
  ),

  //Hotels
  HGW_API: getConfig("HGW_API", `http://hotelgw${API_ENV}.eshet.com`),
  // HGWC_API: getConfig("HGWC_API", `http://hgwcore-${API_ENV}.eshet.com/Api`),
  HGWC_API: getConfig("HGWC_API", null),
  HGW_API_AUTH: getConfig("HGW_API_AUTH", null),
  HGW_TIMEOUT: 60000,

  //Organized
  TGW_API: getConfig("TGW_API", `http://tourgw${API_ENV}.eshet.com/Api`),
  TGW_TIMEOUT: parseInt(getConfig("TGW_TIMEOUT", 60000)),

  //mail config
  MAIL_API: getConfig("MAIL_API", `http://mail-${API_ENV}.eshet.com:45`),
  DOMESTIC_ORDER_BCC: getConfig("DOMESTIC_ORDER_BCC", "shani.kotter@eshet.com"),
  ABROAD_ORDER_BCC: getConfig("ABROAD_ORDER_BCC", "shani.kotter@eshet.com"),
  AGENT_BCC_MAIL: getConfig(
    "AGENT_BCC_MAIL",
    "outgoing-dashboard@eshet-tours.co.il"
  ),
  AFTER_SALE_MAIL: getConfig("AFTER_SALE_MAIL", "orders@eshet.com"),
  // mail shikum prod
  MAIL_SHIKUM_API: getConfig(
    "MAIL_SHIKUM_API",
    `https://shikummailwebapp.azurewebsites.net`
  ),

  //Umbraco Api
  UMBRACO_API: getConfig(
    "UMBRACO_API",
    `https://umbraco${API_ENV}.eshet.com/umbraco/api`
  ),
  UMBRACO_TIMEOUT: 60000,

  ESHET_FLIGHTS_CANCELLATION: getConfig(
    "ESHET_FLIGHTS_CANCELLATION",
    "22--25,15-21-50,8-14-75,-7-100"
  ),

  //new relic
  ENABLE_NEWRELIC: getConfig("ENABLE_NEWRELIC", false) == "true",
  NEW_RELIC_API_KEY: getConfig("NEW_RELIC_API_KEY", null),
  NEW_RELIC_ENV: getConfig("NEW_RELIC_ENV", `ordersUI${API_ENV}`),

  // location api
  IP_STACK_URL: getConfig("IP_STACK_URL", null),
  IP_STACK_KEY: getConfig("IP_STACK_KEY", null),

  // eshet groups
  ESHET_GROUPS_BCC: getConfig(
    "ESHET_GROUPS_BCC",
    "groupsres@eshet-tours.co.il"
  ),
  NEXTAUTH_URL: getConfig("NEXTAUTH_URL", null),
  NEXTAUTH_URL_INTERNAL: getConfig("NEXTAUTH_URL_INTERNAL", null)
}
module.exports = config
