/* global fetch */

import { call, put, takeLatest, takeEvery } from "redux-saga/effects"
import es6promise from "es6-promise"
import "isomorphic-unfetch"
import {
  actionTypes,
  updateAgents,
  loginErrorAction,
  updateDestinations,
  updateIpsData
} from "../actions/generalActions"

es6promise.polyfill()

function* loadAgentsSaga({ productGroup }) {
  const path = `/users/groupagents?productGroup=${productGroup}`
  const agentsRaw = yield call(() => fetch(path))
  const agents = yield agentsRaw.json()
  yield put(updateAgents(agents))
}

function* loadDestinationsSaga({ destinations, destType }) {
  try {
    const path = `/data/getDestinations`
    const resRaw = yield call(() =>
      fetch(path, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ destinations, destType })
      })
    )
    const destinationsResults = yield resRaw.json()
    if (destinationsResults) {
      yield put(updateDestinations(destinationsResults))
    }
  } catch (e) {
    console.log(e)
  }
}

export default [
  takeLatest(actionTypes.LOAD_AGENTS, loadAgentsSaga),
  takeEvery(actionTypes.LOAD_DESTINATIONS, loadDestinationsSaga)
]
