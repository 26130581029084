/* global fetch */

import { call, put, takeLatest } from "redux-saga/effects"
import es6promise from "es6-promise"
import "isomorphic-unfetch"
import {
  actionTypes,
  updateResults,
  startLoading,
  updateOrderFieldAction,
  updateFormStatusAction,
  toggleAddItemFormAction
} from "../actions/ordersActions"
import { SUCCESS, ERROR, LOADING, UNEXCPECTED_ERROR } from "../scripts/consts"
import { ADD_SUBSIDY_ORDER } from "../scripts/forms"
import {
  getApiUrlByResourceType,
  getDeleteUrlByResourceType,
  getUpdateUrlByResourseType
} from "../scripts/general"

es6promise.polyfill()

function* loadResultsSaga({ params, resourceType }) {
  yield put(startLoading())
  const apiUrl = getApiUrlByResourceType(resourceType)
  const resultsRaw = yield call(() =>
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(params)
    })
  )

  const results = yield resultsRaw.json()
  yield put(updateResults(results, resourceType))
}
function* loadShikumResultsSaga({ params, resourceType }) {
  yield put(startLoading())
  const apiUrl = getApiUrlByResourceType(resourceType)
  const resultsRaw = yield call(() =>
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(params)
    })
  )

  const results = yield resultsRaw.json()
  yield put(updateResults(results, resourceType))
}
function* loadShikumCancellationsResultsSaga({ params, resourceType }) {
  yield put(startLoading())
  const apiUrl = getApiUrlByResourceType(resourceType)
  const resultsRaw = yield call(() =>
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(params)
    })
  )

  const results = yield resultsRaw.json()
  yield put(updateResults(results, resourceType))
}

function* updateOrderSaga({ params }) {
  const path = `/orders/update`
  const updateResRaw = yield call(() =>
    fetch(path, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params)
    })
  )
  const updateRes = yield updateResRaw.json()
  const { orderId } = params
  if (updateRes && updateRes.success) {
    yield put(updateOrderFieldAction(orderId, "savingError", false))
  } else {
    yield put(updateOrderFieldAction(orderId, "savingError", true))
  }
}
function* updateShikumOrderSaga({ params }) {
  const path = `/shikum/update`
  const updateResRaw = yield call(() =>
    fetch(path, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params)
    })
  )
  const updateRes = yield updateResRaw.json()
  const { orderId } = params
  if (updateRes && updateRes.success) {
    yield put(updateOrderFieldAction(orderId, "savingError", false))
  } else {
    yield put(updateOrderFieldAction(orderId, "savingError", true))
  }
}

function* submitFormSaga({ formData, formName }) {
  yield put(updateFormStatusAction(formName, LOADING, null))
  if (formName == ADD_SUBSIDY_ORDER) {
    const path = `/subsidy/addOrder`
    const resRaw = yield call(() =>
      fetch(path, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData)
      })
    )
    const result = yield resRaw.json()
    if (result && result.success) {
      yield put(updateFormStatusAction(formName, SUCCESS, null))
      yield put(toggleAddItemFormAction())
    } else {
      const error =
        result && result.message ? result.message : UNEXCPECTED_ERROR
      yield put(updateFormStatusAction(formName, ERROR, error))
    }
  } else {
    yield put(updateFormStatusAction(formName, ERROR, UNEXCPECTED_ERROR))
  }
}

function* sendOrderMailSaga({ orderId, orderStatus }) {
  const path = `/orders/resendMail`
  const sendMailResRaw = yield call(() =>
    fetch(path, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ orderId, orderStatus })
    })
  )
  if (sendMailResRaw && sendMailResRaw.status == 200) {
    const sendMailRes = yield sendMailResRaw.json()
  } else {
    console.log("Error in sending mail")
  }
}
function* sendOrderMailShikumSaga({ orderId, orderStatus }) {
  const path = `/shikum/resendMail`
  const sendMailResRaw = yield call(() =>
    fetch(path, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ orderId, orderStatus })
    })
  )
  if (sendMailResRaw && sendMailResRaw.status == 200) {
    const sendMailRes = yield sendMailResRaw.json()
  } else {
    console.log("Error in sending mail")
  }
}

function* deleteItemSaga({ itemId, resourceType }) {
  yield put(updateOrderFieldAction(itemId, "deleteError", false))
  yield put(updateOrderFieldAction(itemId, "deleteStatus", LOADING))
  yield put(startLoading())
  const apiUrl = getDeleteUrlByResourceType(resourceType)
  if (apiUrl) {
    const resultsRaw = yield call(() =>
      fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ itemId })
      })
    )

    const result = yield resultsRaw.json()
    if (result && result.success) {
      yield put(updateOrderFieldAction(itemId, "deleteError", false))
      yield put(updateOrderFieldAction(itemId, "deleteStatus", SUCCESS))
    } else {
      const error =
        result && result.message ? result.message : UNEXCPECTED_ERROR
      yield put(updateOrderFieldAction(itemId, "deleteError", error))
      yield put(updateOrderFieldAction(itemId, "deleteStatus", ERROR))
    }
  } else {
    yield put(updateOrderFieldAction(itemId, "deleteError", UNEXCPECTED_ERROR))
  }
}

function* updateItemSaga({ itemId, amount, resourceType }) {
  yield put(updateOrderFieldAction(itemId, "updateError", false))
  yield put(updateOrderFieldAction(itemId, "updateStatus", LOADING))
  yield put(startLoading())
  const apiUrl = getUpdateUrlByResourseType(resourceType)
  if (apiUrl) {
    const resultsRaw = yield call(() =>
      fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ itemId, amount })
      })
    )

    const result = yield resultsRaw.json()
    if (result && result.success) {
      yield put(updateOrderFieldAction(itemId, "updateError", false))
      yield put(updateOrderFieldAction(itemId, "updateStatus", SUCCESS))
      yield put(updateOrderFieldAction(itemId, "subsidyAmount", amount))
    } else {
      const error =
        result && result.message ? result.message : UNEXCPECTED_ERROR
      yield put(updateOrderFieldAction(itemId, "updateError", error))
      yield put(updateOrderFieldAction(itemId, "updateStatus", ERROR))
    }
  } else {
    yield put(updateOrderFieldAction(itemId, "updateError", UNEXCPECTED_ERROR))
  }
}

export default [
  takeLatest(actionTypes.LOAD_ORDERS, loadResultsSaga),
  takeLatest(actionTypes.UPDATE_ORDER, updateOrderSaga),
  takeLatest(actionTypes.LOAD_SHIKUM_ORDERS, loadShikumResultsSaga),
  takeLatest(
    actionTypes.LOAD_SHIKUM_CANCELLATIONS,
    loadShikumCancellationsResultsSaga
  ),
  takeLatest(actionTypes.UPDATE_SHIKUM_ORDER, updateShikumOrderSaga),
  takeLatest(actionTypes.SEND_ORDER_MAIL, sendOrderMailSaga),
  takeLatest(actionTypes.SEND_ORDER_MAIL_SHIKUM, sendOrderMailShikumSaga),
  takeLatest(actionTypes.SUBMIT_FORM, submitFormSaga),
  takeLatest(actionTypes.DELETE_ITEM, deleteItemSaga),
  takeLatest(actionTypes.UPDATE_ITEM, updateItemSaga)
]
