import { createStore, applyMiddleware, combineReducers, Store } from "redux"
import { MakeStore, createWrapper, Context } from "next-redux-wrapper"
import createSagaMiddleware, { Task } from "redux-saga"
import { all } from "redux-saga/effects"

import generalReducer, {
  initialState as generalInitialState
} from "./reducers/generalReducer"
import ordersReducer, {
  initialState as ordersInitialState
} from "./reducers/ordersReducer"

import generalSaga from "./sagas/generalSaga"
import ordersSaga from "./sagas/ordersSaga"

const rootReducer = combineReducers({
  general: generalReducer,
  orders: ordersReducer
})
const initialStateObj: any = {
  general: generalInitialState,
  orders: ordersInitialState
}

function* rootSaga() {
  yield all([...generalSaga, ...ordersSaga])
}

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== "production") {
    const { composeWithDevTools } = require("redux-devtools-extension")
    return composeWithDevTools(applyMiddleware(...middleware))
  }
  return applyMiddleware(...middleware)
}

export interface SagaStore extends Store {
  sagaTask?: Task
}

export const makeStore: MakeStore<typeof initialStateObj> = (
  context: Context
) => {
  const sagaMiddleware = createSagaMiddleware()
  const store: any = createStore(rootReducer, bindMiddleware([sagaMiddleware]))

  store.sagaTask = sagaMiddleware.run(rootSaga)

  return store
}

export const wrapper = createWrapper<typeof initialStateObj>(makeStore, {
  // debug: true
})
