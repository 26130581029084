export const actionTypes: any = {
  UPDATE_RESULTS: "UPDATE_RESULTS",
  LOAD_ORDERS: "LOAD_ORDERS",
  LOAD_SHIKUM_ORDERS: "LOAD_SHIKUM_ORDERS",
  LOAD_SHIKUM_CANCELLATIONS: "LOAD_SHIKUM_CANCELLATIONS",
  START_LOADING: "START_LOADING",
  UPDATE_FILTER: "UPDATE_FILTER",
  CLEAR_FILTERS: "CLEAR_FILTERS",
  UPDATE_ACTIVE_FILTER_TAB: "UPDATE_ACTIVE_FILTER_TAB",
  UPDATE_ORDER_FIELD: "UPDATE_ORDER_FIELD",
  UPDATE_ORDER: "UPDATE_ORDER",
  UPDATE_SHIKUM_ORDER: "UPDATE_SHIKUM_ORDER",
  SEND_ORDER_MAIL: "SEND_ORDER_MAIL",
  SEND_ORDER_MAIL_SHIKUM: "SEND_ORDER_MAIL_SHIKUM",
  UPDATE_MAIL_STATUS: "UPDATE_MAIL_STATUS",
  UPDATE_RESOURCE_TYPE: "UPDATE_RESOURCE_TYPE",
  TOGGLE_ADD_ITEM_FORM: "TOGGLE_ADD_ITEM_FORM",
  SUBMIT_FORM: "SUBMIT_FORM",
  UPDATE_FORM_STATUS: "UPDATE_FORM_STATUS",
  DELETE_ITEM: "DELETE_ITEM",
  UPDATE_ITEM: "UPDATE_ITEM"
}

export function loadResults(params, resourceType?) {
  return {
    type: actionTypes.LOAD_ORDERS,
    params,
    resourceType
  }
}
export function loadShikumResults(params, resourceType?) {
  return {
    type: actionTypes.LOAD_SHIKUM_ORDERS,
    params,
    resourceType
  }
}
export function loadShikumCancellationsResults(params, resourceType?) {
  return {
    type: actionTypes.LOAD_SHIKUM_CANCELLATIONS,
    params,
    resourceType
  }
}

export function updateResults(ordersResults, resourceType?) {
  return {
    type: actionTypes.UPDATE_RESULTS,
    ordersResults,
    resourceType
  }
}

export function startLoading() {
  return {
    type: actionTypes.START_LOADING
  }
}

export function updateFilter(filterName, filterValue) {
  return {
    type: actionTypes.UPDATE_FILTER,
    filterName,
    filterValue
  }
}

export function clearFiltersAction() {
  return {
    type: actionTypes.CLEAR_FILTERS
  }
}

export function updateActiveFilterTab(filterTab) {
  return {
    type: actionTypes.UPDATE_ACTIVE_FILTER_TAB,
    filterTab
  }
}

export function updateOrderFieldAction(orderId, key, value) {
  return {
    type: actionTypes.UPDATE_ORDER_FIELD,
    orderId,
    key,
    value
  }
}

export function updateOrderAction(params) {
  return {
    type: actionTypes.UPDATE_ORDER,
    params
  }
}
export function updateOrderShikumAction(params) {
  return {
    type: actionTypes.UPDATE_SHIKUM_ORDER,
    params
  }
}

export function sendOrderMailAction(orderId, orderStatus) {
  return {
    type: actionTypes.SEND_ORDER_MAIL,
    orderId,
    orderStatus
  }
}
export function sendOrderMailShikumAction(orderId, orderStatus) {
  return {
    type: actionTypes.SEND_ORDER_MAIL_SHIKUM,
    orderId,
    orderStatus
  }
}

export function updateResourceTypeAction(resourceType) {
  return {
    type: actionTypes.UPDATE_RESOURCE_TYPE,
    resourceType
  }
}

export function toggleAddItemFormAction() {
  return {
    type: actionTypes.TOGGLE_ADD_ITEM_FORM
  }
}

export function submitFormAction(formData, formName) {
  return {
    type: actionTypes.SUBMIT_FORM,
    formData,
    formName
  }
}

export function updateFormStatusAction(formName, status, error) {
  return {
    type: actionTypes.UPDATE_FORM_STATUS,
    formName,
    status,
    error
  }
}

//
export function deleteItemAction(itemId, resourceType) {
  return {
    type: actionTypes.DELETE_ITEM,
    itemId,
    resourceType
  }
}

export function updateItemAction(itemId, amount, resourceType) {
  return {
    type: actionTypes.UPDATE_ITEM,
    itemId,
    amount,
    resourceType
  }
}
