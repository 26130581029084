import { actionTypes } from "../actions/generalActions"
import { airportsData } from "../scripts/consts"
import { HYDRATE } from "next-redux-wrapper"
import { isNullOrEmpty } from "../scripts/general"

export const initialState = {
  user: null,
  orderId: null,
  params: {},
  agents: [],
  loginError: null,
  airportsData,
  destinations: {},
  pageTitle: null,
  pageType: null,
  errors: {},
  ipsData: {},
  session: {},
  config: {}
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE: {
      const nextState = {
        ...state, // use previous state
        ...action.payload.general // apply delta from hydration
      }
      for (let key in state) {
        // preserve client state
        if (state[key] && !isNullOrEmpty(state[key]))
          nextState[key] = state[key]
      }
      return nextState
    }
    case actionTypes.UPDATE_PAGE_TITLE:
      return {
        ...state,
        pageTitle: action.pageTitle
      }
    case actionTypes.CURRENT_ORDERS_TYPE:
      return {
        ...state,
        currentOrdersType: action.currentOrdersType
      }
    case actionTypes.UPDATE_PAGE_TYPE:
      return {
        ...state,
        pageType: action.pageType
      }
    case actionTypes.UPDATE_USER:
      return {
        ...state,
        user: action.user
      }
    case actionTypes.UPDATE_ORDER_ID:
      return {
        ...state,
        orderId: action.orderId
      }

    case actionTypes.UPDATE_DATE_RANGE:
      return {
        ...state,
        params: {
          ...state.params,
          startDate: action.startDate,
          endDate: action.endDate
        }
      }
    case actionTypes.UPDATE_AGENTS:
      return {
        ...state,
        agents: action.agents
      }

    case actionTypes.UPDATE_CONFIG:
      return {
        ...state,
        config: {
          ...state.config,
          ...action.config
        }
      }

    case actionTypes.LOGIN_ERROR:
      return {
        ...state,
        loginError: action.message
      }

    case actionTypes.UPDATE_DESTINATIONS:
      const destinations = { ...state.destinations }
      action.destinations &&
        Object.keys(action.destinations).forEach((key) => {
          destinations[key] = destinations[key] || action.destinations[key]
        })

      return {
        ...state,
        destinations
      }

    case actionTypes.UPDATE_IPS_DATA:
      return {
        ...state,
        ipsData: { ...state.ipsData, ...action.ipsData }
      }

    case actionTypes.SAVE_SESSION:
      return {
        ...state,
        session: action.session
      }

    default:
      return state
  }
}

export default reducer
