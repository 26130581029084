import { actionTypes } from "../actions/ordersActions"
import { FILTER_TABS } from "../scripts/filtersLogic"
import { ORDER } from "../scripts/consts"
import { HYDRATE } from "next-redux-wrapper"
import { isNullOrEmpty } from "../scripts/general"

export const initialState = {
  orders: null,
  loading: true,
  selectedFilters: {},
  activeFilterTab: FILTER_TABS.ALL,
  resourceType: null,
  showAddItemForm: false
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE: {
      const nextState = {
        ...state, // use previous state
        ...action.payload.orders // apply delta from hydration
      }
      for (let key in state) {
        // preserve client state
        if (state[key] && !isNullOrEmpty(state[key]))
          nextState[key] = state[key]
      }
      return nextState
    }
    case actionTypes.UPDATE_RESULTS:
      return {
        ...state,
        ...{
          orders: action.ordersResults ? action.ordersResults.results : [],
          loading: false
        }
      }
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true
      }
    case actionTypes.UPDATE_ACTIVE_FILTER_TAB:
      return {
        ...state,
        activeFilterTab: action.filterTab
      }
    case actionTypes.UPDATE_ORDER_FIELD:
      const { orderId, key, value } = action
      return {
        ...state,
        orders: updateOrderField(state.orders, orderId, key, value)
      }
    case actionTypes.UPDATE_FILTER:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [action.filterName]: action.filterValue
        }
      }
    case actionTypes.CLEAR_FILTERS:
      return {
        ...state,
        selectedFilters: {}
      }
    case actionTypes.UPDATE_RESOURCE_TYPE:
      return {
        ...state,
        resourceType: action.resourceType
      }
    case actionTypes.TOGGLE_ADD_ITEM_FORM:
      return {
        ...state,
        showAddItemForm: !state.showAddItemForm
      }
    case actionTypes.UPDATE_FORM_STATUS:
      const { formName, status, error } = action
      return {
        ...state,
        [formName]: { status, error }
      }
    default:
      return state
  }
}

export default reducer

function updateOrderField(orders, orderId, key, value) {
  const newOrders = [...orders]
  let orderIndex = orders.findIndex((order) => order.orderId == orderId)
  newOrders[orderIndex] = {
    ...newOrders[orderIndex],
    [key]: value
  }
  return newOrders
}
