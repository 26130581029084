import { SELECT, DATEPICKER, HIDDEN } from './consts'
import moment from 'moment'
export const ADD_SUBSIDY_ORDER = 'ADD_SUBSIDY_ORDER'

export const ADD_SUBSIDY_ORDER_CONFIG = {
    name: ADD_SUBSIDY_ORDER,
    caption: 'הוספת הזמנה',
    fields:
    {
        'clientId': {
            defaultVal: '',
            placeholder: "תעודת זהות",
            validations: [isRequired]
        },
        'docketId': {
            defaultVal: '',
            placeholder: "מספר דוקט",
            convertVal: (val) => parseInt(val),
            validations: [isRequired]
        },
        'amount': {
            defaultVal: '',
            placeholder: "סכום סבסוד",
            convertVal: (val) => parseInt(val),
            validations: [isRequired]
        },
        'departmentType': {
            defaultVal: '',
            placeholder: "סוג מוצר",
            type: SELECT,
            convertVal: (val) => parseInt(val),
            options: [{ val: 1, name: "פנים" }, { val: 2, name: 'חו"ל' }],
            validations: [isRequired]
        },
        'vacationDate': {
            defaultVal: new Date(),
            placeholder: "תאריך יציאה",
            type: DATEPICKER,
            convertVal: (val) => moment(val).format('DD.MM.YYYY'),
            validations: [isRequired]
        },
        'Agent': {
            defaultVal: '',
            type: HIDDEN
        }
    }
}

function isRequired(val) {
    if (!val || val == '') {
        return 'זהו שדה חובה'
    }
    return null
}
