export const actionTypes: any = {
  UPDATE_USER: "UPDATE_USER",
  UPDATE_DATE_RANGE: "UPDATE_DATE_RANGE",
  UPDATE_ORDER_ID: "UPDATE_ORDER_ID",
  LOAD_AGENTS: "LOAD_AGENTS",
  UPDATE_AGENTS: "UPDATE_AGENTS",
  LOGIN_ERROR: "LOGIN_ERROR",
  LOAD_DESTINATIONS: "LOAD_DESTINATIONS",
  UPDATE_DESTINATIONS: "UPDATE_DESTINATIONS",
  UPDATE_PAGE_TITLE: "UPDATE_PAGE_TITLE",
  CURRENT_ORDERS_TYPE: "CURRENT_ORDERS_TYPE",
  UPDATE_PAGE_TYPE: "UPDATE_PAGE_TYPE",
  UPDATE_IPS_DATA: "UPDATE_IPS_DATA",
  LOAD_IPS_DATA: "LOAD_IPS_DATA",
  UPDATE_CONFIG: "UPDATE_CONFIG"
}

export function updatePageTitle(pageTitle) {
  return {
    type: actionTypes.UPDATE_PAGE_TITLE,
    pageTitle
  }
}

export function updateOrdersType(currentOrdersType) {
  return {
    type: actionTypes.CURRENT_ORDERS_TYPE,
    currentOrdersType
  }
}
export function updatePageType(pageType) {
  return {
    type: actionTypes.UPDATE_PAGE_TYPE,
    pageType
  }
}

export function updateUser(user) {
  return {
    type: actionTypes.UPDATE_USER,
    user
  }
}

export function updateConfigAction(config) {
  return {
    type: actionTypes.UPDATE_CONFIG,
    config
  }
}

export function updateOrderId(orderId) {
  return {
    type: actionTypes.UPDATE_ORDER_ID,
    orderId
  }
}

export function updateDateRange(startDate, endDate) {
  return {
    type: actionTypes.UPDATE_DATE_RANGE,
    startDate,
    endDate
  }
}

export function loadAgents(productGroup) {
  return {
    type: actionTypes.LOAD_AGENTS,
    productGroup
  }
}

export function updateAgents(agents) {
  return {
    type: actionTypes.UPDATE_AGENTS,
    agents
  }
}

export function loginErrorAction(message) {
  return {
    type: actionTypes.LOGIN_ERROR,
    message
  }
}

export function loadDestinations(destinations, destType) {
  return {
    type: actionTypes.LOAD_DESTINATIONS,
    destinations,
    destType
  }
}

export function updateDestinations(destinations) {
  return {
    type: actionTypes.UPDATE_DESTINATIONS,
    destinations
  }
}

export function updateIpsData(ipsData) {
  return {
    type: actionTypes.UPDATE_IPS_DATA,
    ipsData
  }
}
