import memoizeOne from "memoize-one"
import {
  E_FilterTypes,
  E_ClubsLogos,
  E_VendorNames,
  E_OrderStatuses,
  E_PaymentStatusesName,
  E_PaymentMethodsNames,
  E_PaymentMethodsImages,
  E_agent_statuses
} from "./enums"
import {
  isInString,
  getProductList,
  getVendorsList,
  getProductName,
  groupBy
} from "../scripts/general"
//import { getVendorsList, getProductList, convertVendor, isNullOrEmpty } from '../scripts/general'

export const ORDER_NUMBER = "orderNumber"
export const CUSTOMER_DETAILS = "customerDetails"
export const CUSTOMER_CLUB = "customerClub"
export const CUSTOMER_STATUS = "customerStatus"
export const DESTINATION_NAME = "productDetails"
export const PRODUCT_TYPE = "productType"
export const PRODUCT_VENDOR = "productVendor"
export const ORDER_STATUS = "orderStatus"
export const PAYMENT_STATUS = "paymentStatus"
export const PAYMENT_TYPE = "paymentType"
export const AGENT_NAME = "agentName"
export const AGENT_STATUS = "agentStatus"
export const AGENT_REMARKS = "agentRemarks"
export const ORDER_SOURCE = "orderSource"
export const ORDER_DEVICE = "orderDevice"
export const CUSTOMER_NAME_ID = "customerNameOrId"
export const PRODUCT_DEPARTMENT = "productDepartment"
export const CUSTOMER_NAME = "customerName"
export const CUSTOMER_ID = "customerId"
export const EMPLOYEE_ID = "employeeId"
export const CUSTOMER_PHONE = "customerPhone"
export const CUSTOMER_EMAIL = "customerEmail"
export const PRODUCT_DETAILS = "productDetails"
export const AGENT_CUSTOMER_DETAILS = "agentCustomerDetails"
export const AGENCY = "agency"
export const POINT_OF_SALE = "pointOfSale"

export const FILTER_TABS = {
  ALL: "ALL",
  MY_ORDERS: "MY_ORDERS",
  ORDERS_WITHOUT_USER: "ORDERS_WITHOUT_USER"
}

export const filteredOrdersMem = memoizeOne(
  (orders, selectedFilters, activeFilterTab, userName) => {
    let filteredOrders = [...orders]
    if (activeFilterTab && filtersFuncs[activeFilterTab]) {
      filteredOrders = filtersFuncs[activeFilterTab](filteredOrders, userName)
    }
    if (selectedFilters) {
      Object.keys(selectedFilters).forEach((filterName) => {
        if (filtersFuncs[filterName] && selectedFilters[filterName]) {
          filteredOrders = filtersFuncs[filterName](
            selectedFilters[filterName],
            filteredOrders
          )
        }
      })
    }
    return filteredOrders
  }
)

export const filtersFuncs = {
  [FILTER_TABS.MY_ORDERS]: (orders, userName) => {
    if (userName) {
      return orders.filter((item) => item.agentName == userName)
    } else {
      return orders
    }
  },
  [FILTER_TABS.ORDERS_WITHOUT_USER]: (orders, user) => {
    return orders.filter((order) => !order.agentName || order.agentName == "")
  },
  [ORDER_NUMBER]: (value, array) =>
    textFilter(
      value,
      array,
      (item) =>
        item.orderId +
        "|" +
        item.docketId +
        "|" +
        item.sessionID +
        "|" +
        item.yayaId
    ),
  [CUSTOMER_DETAILS]: (value, array) =>
    textFilter(
      value,
      array,
      (item) => item.clientName + "|" + item.clientPhone
    ),
  [CUSTOMER_NAME_ID]: (value, array) =>
    textFilter(value, array, (item) => item.clientName + "|" + item.clientId),
  [CUSTOMER_CLUB]: (value, array) =>
    arrayFilter(value, array, (item) => item.clientClubType),
  [AGENCY]: (value, array) => arrayFilter(value, array, (item) => item.agency),
  [POINT_OF_SALE]: (value, array) =>
    arrayFilter(value, array, (item) => item.pointOfSale),
  [CUSTOMER_STATUS]: (value, array) =>
    arrayFilter(value, array, (item) => (item.isActive ? 1 : 0)),
  [DESTINATION_NAME]: (value, array) =>
    textFilter(value, array, (item) => item.destination),
  [PRODUCT_TYPE]: (value, array) =>
    arrayFilter(value, array, (item) => item.siteProductType),
  [PRODUCT_VENDOR]: (value, orders) => {
    let valuesArray =
      typeof value == "string"
        ? value.split(",").map((x) => parseInt(x))
        : value
    if (valuesArray && valuesArray.length > 0) {
      return orders.filter(
        (item) =>
          item.vendors && item.vendors.some((v) => valuesArray.indexOf(v) != -1)
      )
    } else {
      return orders
    }
  },
  [ORDER_STATUS]: (value, array) =>
    arrayFilter(value, array, (item) => item.orderStatus),
  [PAYMENT_STATUS]: (value, array) =>
    arrayFilter(value, array, (item) => item.PaymentStatus),
  [PAYMENT_TYPE]: (value, array) => {
    let valuesArray =
      typeof value == "string"
        ? value.split(",").map((x) => parseInt(x))
        : value
    if (valuesArray && valuesArray.length > 0) {
      return array.filter(
        (item) =>
          valuesArray.indexOf(item.paymentGatewayType) != -1 ||
          (value.indexOf(99) != -1 && item.subsidyAmount > 0) ||
          (value.indexOf(100) != -1 && item.couponAmount > 0)
      )
    } else {
      return array
    }
  },
  [AGENT_NAME]: (value, array) =>
    textFilter(value, array, (item) => item.agentName),
  [AGENT_STATUS]: (value, array) =>
    arrayFilter(value, array, (item) => item.agentStatus),
  [AGENT_REMARKS]: (value, array) =>
    textFilter(value, array, (item) => item.agentRemarks),
  [ORDER_SOURCE]: (value, array) =>
    textFilter(value, array, (item) => item.source),
  [ORDER_DEVICE]: (value, array) =>
    textFilter(value, array, (item) => item.device),
  [PRODUCT_DEPARTMENT]: (value, array) =>
    textFilter(value, array, (item) => item.department),
  [CUSTOMER_NAME]: (value, array) =>
    textFilter(value, array, (item) => item.clientName),
  [CUSTOMER_ID]: (value, array) =>
    textFilter(value, array, (item) => item.clientId),
  [EMPLOYEE_ID]: (value, array) =>
    textFilter(value, array, (item) => item.workerId),
  [CUSTOMER_PHONE]: (value, array) =>
    textFilter(value, array, (item) => item.phone),
  [CUSTOMER_EMAIL]: (value, array) =>
    textFilter(value, array, (item) => item.email)
}

export const filterGroupsMem = memoizeOne(
  (selectedFilters, orders, productGroup) => {
    let filterGroups = {}
    Object.keys(filterGroupsConfig).map((key) => {
      const filterGroup = filterGroupsConfig[key]
      filterGroups[key] = {
        ...filterGroup,
        filters: filterGroup.filters
          ? filterGroup.filters.map((filter) =>
              convertFilter(filter, selectedFilters, orders, productGroup)
            )
          : []
      }
    })
    return filterGroups
  }
)

const convertFilter = (filter, selectedFilters, orders, productGroup) => {
  const defaultVal = filter.type == E_FilterTypes.Input ? "" : []
  const value =
    selectedFilters && selectedFilters[filter.name]
      ? selectedFilters[filter.name]
      : defaultVal
  const isFiltered = value && value.length > 0
  return {
    ...filter,
    options: filter.initOptions
      ? filter.initOptions(orders, productGroup)
      : undefined,
    value,
    isFiltered
  }
}

const filterGroupsConfig = {
  [ORDER_NUMBER]: {
    name: "פרטי הזמנה",
    filters: [
      {
        name: ORDER_NUMBER,
        type: E_FilterTypes.Input,
        placeholder: "הקלד ערך לחיפוש"
      }
    ]
  },
  [CUSTOMER_DETAILS]: {
    name: "פרטי הלקוח",
    filters: [
      {
        name: CUSTOMER_DETAILS,
        type: E_FilterTypes.Input,
        placeholder: "הקלד שם או טלפון"
      },
      {
        name: CUSTOMER_CLUB,
        type: E_FilterTypes.Checkbox,
        placeholder: "מועדון",
        initOptions: (orders, productGroup) => {
          return Object.keys(E_ClubsLogos).map((key) => ({
            val: key,
            img: E_ClubsLogos[key]
          }))
        },
        isOpen: true
      }
    ]
  },
  [CUSTOMER_NAME_ID]: {
    name: "פרטי הלקוח",
    filters: [
      {
        name: CUSTOMER_NAME_ID,
        type: E_FilterTypes.Input,
        placeholder: "הקלד שם או תעודת זהות"
      }
    ]
  },
  [CUSTOMER_NAME]: {
    name: "שם הלקוח",
    filters: [
      {
        name: CUSTOMER_NAME,
        type: E_FilterTypes.Input,
        placeholder: "הקלד שם"
      }
    ]
  },
  [PRODUCT_DEPARTMENT]: {
    name: "סוג מוצר",
    filters: [
      {
        name: PRODUCT_DEPARTMENT,
        type: E_FilterTypes.Checkbox,
        placeholder: "סוג מוצר",
        initOptions: (orders, productGroup) => {
          return [
            { val: 1, name: "פנים" },
            { val: 2, name: 'חו"ל' }
          ]
        },
        isOpen: true
      }
    ]
  },
  [CUSTOMER_CLUB]: {
    name: "סוג מועדון",
    filters: [
      {
        name: CUSTOMER_CLUB,
        type: E_FilterTypes.Checkbox,
        placeholder: "סוג מועדון",
        initOptions: (orders, productGroup) => {
          return Object.keys(E_ClubsLogos).map((key) => ({
            val: key,
            img: E_ClubsLogos[key]
          }))
        },
        isOpen: true
      }
    ]
  },
  [CUSTOMER_STATUS]: {
    name: "פעיל",
    filters: [
      {
        name: CUSTOMER_STATUS,
        type: E_FilterTypes.Checkbox,
        placeholder: "פעיל",
        initOptions: (orders, productGroup) => {
          return [
            { val: 1, name: "פעיל" },
            { val: 0, name: "לא פעיל" }
          ]
        },
        isOpen: true
      }
    ]
  },
  [AGENT_NAME]: {
    name: "סוכן",
    filters: [
      {
        name: AGENT_NAME,
        type: E_FilterTypes.Input,
        placeholder: "הקלד סוכן"
      }
    ]
  },
  [CUSTOMER_ID]: {
    name: "תעודת זהות",
    filters: [
      {
        name: CUSTOMER_ID,
        type: E_FilterTypes.Input,
        placeholder: "הקלד תעודת זהות"
      }
    ]
  },
  [EMPLOYEE_ID]: {
    name: "מספר עובד",
    filters: [
      {
        name: EMPLOYEE_ID,
        type: E_FilterTypes.Input,
        placeholder: "הקלד מספר עובד"
      }
    ]
  },
  [CUSTOMER_PHONE]: {
    name: "טלפון",
    filters: [
      {
        name: CUSTOMER_PHONE,
        type: E_FilterTypes.Input,
        placeholder: "הקלד טלפון"
      }
    ]
  },
  [CUSTOMER_EMAIL]: {
    name: "כתובת מייל",
    filters: [
      {
        name: CUSTOMER_EMAIL,
        type: E_FilterTypes.Input,
        placeholder: "הקלד כתובת מייל"
      }
    ]
  },
  [PRODUCT_DETAILS]: {
    name: "פרטי המוצר",
    filters: [
      {
        name: DESTINATION_NAME,
        type: E_FilterTypes.Input,
        placeholder: "הקלד יעד"
      },
      {
        name: PRODUCT_TYPE,
        type: E_FilterTypes.Checkbox,
        placeholder: "סוג מוצר",
        initOptions: (orders, productGroup) => {
          return getProductList(productGroup).map((x) => ({
            val: x,
            name: getProductName(x)
          }))
        }
      },
      {
        name: PRODUCT_VENDOR,
        type: E_FilterTypes.Checkbox,
        placeholder: "שם הספק",
        initOptions: (orders, productGroup) => {
          return getVendorsList(productGroup).map((x) => ({
            val: x,
            name: E_VendorNames[x]
          }))
        }
      }
    ]
  },
  [ORDER_STATUS]: {
    name: "סטטוס הזמנה",
    filters: [
      {
        name: ORDER_STATUS,
        type: E_FilterTypes.Checkbox,
        placeholder: "סטטוס הזמנה",
        initOptions: (orders, productGroup) => {
          return Object.keys(E_OrderStatuses).map((x) => ({
            val: x,
            name: E_OrderStatuses[x]
          }))
        }
      },
      {
        name: PAYMENT_STATUS,
        type: E_FilterTypes.Checkbox,
        placeholder: "סטטוס תשלום",
        initOptions: (orders, productGroup) => {
          return Object.keys(E_PaymentStatusesName).map((x) => ({
            val: x,
            name: E_PaymentStatusesName[x]
          }))
        }
      },
      {
        name: PAYMENT_TYPE,
        type: E_FilterTypes.Checkbox,
        placeholder: "אופן תשלום",
        initOptions: (orders, productGroup) => {
          return Object.keys(E_PaymentMethodsNames).map((x) => ({
            val: x,
            name: E_PaymentMethodsImages[x]
              ? undefined
              : E_PaymentMethodsNames[x],
            img: E_PaymentMethodsImages[x]
          }))
        }
      }
    ]
  },
  [AGENT_NAME]: {
    name: "סוכן מטפל",
    filters: [
      {
        name: AGENT_NAME,
        type: E_FilterTypes.Input,
        placeholder: "הקלד שם סוכן"
      }
    ]
  },
  [AGENT_STATUS]: {
    name: "סטטוס טיפול",
    filters: [
      {
        name: AGENT_REMARKS,
        type: E_FilterTypes.Input,
        placeholder: "הקלד הערה"
      },
      {
        name: AGENT_STATUS,
        type: E_FilterTypes.Checkbox,
        placeholder: "סטטוס טיפול",
        initOptions: (orders, productGroup) => {
          return Object.keys(E_agent_statuses).map((x) => ({
            val: x,
            name: E_agent_statuses[x]
          }))
        }
      }
    ]
  },
  [ORDER_SOURCE]: {
    name: "מקור",
    filters: [
      {
        name: ORDER_SOURCE,
        type: E_FilterTypes.Input,
        placeholder: "הקלד מקור"
      }
    ]
  },
  [AGENT_CUSTOMER_DETAILS]: {
    name: "פרטי הלקוח",
    filters: [
      {
        name: CUSTOMER_DETAILS,
        type: E_FilterTypes.Input,
        placeholder: "הקלד שם או טלפון"
      },
      {
        name: AGENCY,
        type: E_FilterTypes.Checkbox,
        placeholder: "סוג סוכנות",
        initOptions: (orders, productGroup) => {
          const ordersByAttribute =
            orders && Array.isArray(orders)
              ? groupBy(orders, (order) => order.agency)
              : {}
          return Object.keys(ordersByAttribute)
            .filter((attribute) => attribute)
            .map((attribute) => ({ val: attribute, name: attribute }))
        },
        isOpen: true
      }
    ]
  },
  [POINT_OF_SALE]: {
    name: "נקודת מכירה",
    filters: [
      {
        name: POINT_OF_SALE,
        type: E_FilterTypes.Checkbox,
        placeholder: "נקודת מכירה",
        initOptions: (orders, productGroup) => {
          const ordersByAttribute =
            orders && Array.isArray(orders)
              ? groupBy(orders, (order) => order.pointOfSale)
              : {}
          return Object.keys(ordersByAttribute)
            .filter((attribute) => attribute && attribute != "null")
            .map((attribute) => ({ val: attribute, name: attribute }))
        },
        isOpen: true
      }
    ]
  }
}

const textFilter = (value, array, getValue) => {
  if (value) {
    return array.filter((item) => isInString(getValue(item), value))
  } else {
    return array
  }
}

const arrayFilter = (value, array, getValue) => {
  let valuesArray =
    typeof value == "string" ? value.split(",").map((x) => parseInt(x)) : value
  if (valuesArray && valuesArray.length > 0) {
    return array.filter((item) => valuesArray.indexOf(getValue(item)) != -1)
  } else {
    return array
  }
}
