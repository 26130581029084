import React from "react"
import App, { AppInitialProps } from "next/app"
import { wrapper } from "../store"
import { END } from "redux-saga"
import { withRouter } from "next/router"
import { SessionProvider } from "next-auth/react"
import config from "static/config"
import { updateConfigAction } from "actions/generalActions"

// import 'stylesheets/fontawesome/fontawesome.scss'
// import 'react-datepicker/src/stylesheets/datepicker.scss'
// import 'react-id-swiper/src/styles/scss/swiper.scss'
// import 'slick-carousel/slick/slick.scss'
// import 'slick-carousel/slick/slick-theme.scss'

// moment.updateLocale('he', {
//     weekdays: [
//         "ראשון", "שני", "שלישי", "רביעי", "חמישי", "שישי", "שבת"
//     ],
//     weekdaysShort: ["יום א", "יום ב", "יום ג", "יום ד", "יום ה", "יום ו", "יום ש"],
//     weekdaysMin: ["א", "ב", "ג", "ד", "ה", "ו", "ש"]
// });

class MyApp extends App<AppInitialProps> {
  static async getInitialProps(props) {
    const { Component, ctx } = props
    let pageProps = {}
    const { store, req, query } = ctx

    if (config.NEXTAUTH_URL) {
      store.dispatch(
        updateConfigAction({
          nextAuthUrl: config.NEXTAUTH_URL
        })
      )
    }

    if (config.NEXTAUTH_URL_INTERNAL) {
      store.dispatch(
        updateConfigAction({
          nextAuthUrl: config.NEXTAUTH_URL_INTERNAL
        })
      )
    }

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx)
    }

    if (req) {
      store.dispatch(END)
      await store.sagaTask.toPromise()
    }

    return {
      pageProps: {
        ...pageProps
      }
    }
  }

  componentDidCatch(error, info) {
    console.log(error, info)
  }

  render() {
    const { Component, pageProps } = this.props

    return (
      <SessionProvider session={pageProps.session}>
        <Component {...pageProps} />
      </SessionProvider>
    )
  }
}

export default wrapper.withRedux(withRouter(MyApp))
