export const TO_BE_DELETED = ""
export const DB_DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss"
export const UTC_DATE_TIME_FORMAT = "MM/DD/YYYY HH:mm UTC"
export const DB_DATE_FORMAT = "YYYY-MM-DD"
export const SITE_DATE_FORMAT = "DD.MM.YYYY"
export const SHORT_DATE_FORMAT = "DD.MM.YY"
export const API_DATE_FORMAT = "DD.MM.YYYY"
export const ONE_MINUTE = 1000 * 60

export const airportsData = {
  TLV: 'נתב"ג',
  ETM: 'נמה"ת רמון'
}

export const airlinesData = {
  IZ: {
    Name: "ארקיע",
    Logo: "https://static.eshet.com/10119/arkia_300x300.png"
  },
  IZ1: {
    Name: "ארקיע",
    Logo: "https://static.eshet.com/10322/arkia_lowcost.png"
  },
  "6H": {
    Name: "ישראייר",
    Logo: "https://static.eshet.com/10349/israir.png"
  }
}

export const SUBSIDY_CLIENT = "SUBSIDY_CLIENT"
export const SUBSIDY_ORDER = "SUBSIDY_ORDER"
export const ORDER = "ORDER"
export const SHIKUM = "SHIKUM"
export const SHIKUM_CANCELLATIONS = "SHIKUM_CANCELLATIONS"
export const SELECT = "SELECT"
export const DATEPICKER = "DATEPICKER"
export const HIDDEN = "HIDDEN"

export const SUCCESS = "SUCCESS"
export const ERROR = "ERROR"
export const LOADING = "LOADING"

export const UNEXCPECTED_ERROR = "אירעה שגיאה לא ידועה"

export const DOMESTIC_CANCELLATION_REMARKS = "DomesticCancellationRemarks"
export const ORGANIZED_CANCELLATION_REMARKS = "organizedCancellationRemarks"
export const ORGANIZED_CANCELLATION_PAYMENT_REMARKS =
  "organizedCancellationPaymentRemarks"
export const CONSUMER_PROTECTION_LAW_REMARKS_GROUP = "ConsumerProtectionLaw"
export const AGENT_TRANSACTION_APPROVAL_TERMS = "agentTransactionApprovalTerms"
export const DEALS_REMARKS_ODYSSEA = "ProductPageRemarksOdyssea"
export const DEALS_REMARKS_SABER = "ProductPageRemarksSaber"
export const DEALS_CANCELLATION_ODYSSEA = "dealsCancellationOdyssea"
export const DEALS_CANCELLATION_PAYMENT_ODYSSEA =
  "dealsCancellationPaymentOdyssea"
export const DEALS_CANCELLATION_SABER = "dealsCancellationSaber"
export const DOMESTIC_FLIGHTS_TRANSACTION_REMARKS_ARKIA =
  "TransactionRemarksArkia"
export const DOMESTIC_FLIGHTS_CANCELLATION_REMARKS_ARKIA =
  "CancellationRemarksArkia"
export const DOMESTIC_FLIGHTS_TRANSACTION_REMARKS_ATLANTIS =
  "TransactionRemarksAtlantis"
export const DOMESTIC_FLIGHTS_CANCELLATION_REMARKS_ATLANTIS =
  "CancellationRemarksAtlantis"
export const DOMESTIC_FLIGHTS_CANCELLATION_REMARKS_ISRAIR =
  "CancellationRemarksIsrair"
export const DOMESTIC_FLIGHTS_TRANSACTION_REMARKS_ISRAIR =
  "TransactionRemarksIsrair"
